<template>
    <div class='grid'>
        <div class='col-12'>
            <div class='card'>
                <div class='card-header'>
                    <h4>Elenco abstracts dei Libri</h4>
                </div>
                <DataTable :value='filtered' :paginator='true' class='p-datatable-gridlines' :rows='20' dataKey='id'
                           :rowHover='true'
                           v-model:filters='filters'
                           filterDisplay='menu'
                           :loading='isLoading'
                           responsiveLayout='scroll'
                           :globalFilterFields="['slug']"
                >
                    <template #header>
                        <ListHeader
                            v-model="filters['global'].value"
                            @clear='clearFilter()'
                            :hideNew='true'
                            @refresh='refreshList()'
                            :loading='loading'
                        >
                            <template #after-left>
                                <Dropdown v-model='selectedLanguage'
                                          id='language_search'
                                          dataKey='id'
                                          :options='dd_languages'
                                          class='mb-2 ml-2'
                                          optionLabel='label'
                                          option-value='id'
                                          :filter='true'
                                          filterPlaceholder='Lingua' />
                                <Button type='button'
                                        :class='{"p-button-outlined p-button-info": filterOnQuiz !== -1, "p-button-info": filterOnQuiz === -1}'
                                        title='Tutto' class='mb-2 ml-2' @click='filterOnQuiz = -1'>
                                    Tutti
                                </Button>
                                <Button type='button'
                                        :class='{"p-button-outlined p-button-info": filterOnQuiz !== 1, "p-button-info": filterOnQuiz === 1}'
                                        title='Con Quiz' class='mb-2 ml-2' @click='filterOnQuiz = 1'>
                                    Con Quiz
                                </Button>
                                <Button type='button'
                                        :class='{"p-button-outlined p-button-info": filterOnQuiz !== 2, "p-button-info": filterOnQuiz === 2}'
                                        title='Con Quiz Attivo' class='mb-2 ml-2' @click='filterOnQuiz = 2'>
                                    Con Quiz Attivo
                                </Button>
                                <Button type='button'
                                        :class='{"p-button-outlined p-button-info": filterOnQuiz !== 3, "p-button-info": filterOnQuiz === 3}'
                                        title='Senza Quiz Attivo' class='mb-2 ml-2' @click='filterOnQuiz = 3'>
                                    Senza Quiz Attivo
                                </Button>
                                <Button type='button' title='Senza Quiz'
                                        :class='{"p-button-outlined p-button-info": filterOnQuiz !== 0, "p-button-info": filterOnQuiz === 0}'
                                        class='mb-2 ml-2' @click='filterOnQuiz = 0'>
                                    Senza Quiz
                                </Button>
                            </template>
                        </ListHeader>
                    </template>
                    <template #empty>
                        Non ci sono elementi da mostrare.
                    </template>
                    <template #loading>
                        Loading ... Please wait.
                    </template>


                    <Column field='slug' header='Slug' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.slug }}
                        </template>
                        <template #filter='{filterModel}'>
                            <InputText type='text' v-model='filterModel.value' class='p-column-filter'
                                       placeholder='Search by Slug' />
                        </template>
                    </Column>

                    <Column field='original_title' header='Titolo' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.original_title }}
                        </template>
                    </Column>

                    <Column field='s_cats' header='Categorie' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.s_cats }}
                        </template>
                    </Column>

                    <Column field='s_aut' header='Autori' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.s_aut }}
                        </template>
                    </Column>


                    <Column field='id'>
                        <template #header>
                            Azioni
                            <font-awesome-icon
                                class='pointer ml-2'
                                title='Apri in nuovo Tab'
                                :style='{color: colors.bg.HELP}'
                                :icon="['fas', 'external-link-alt']"
                                size='1x'
                                v-if='linkInNewPage === false'
                                @click='linkInNewPage = true'
                            />
                            <font-awesome-icon
                                class='text-color pointer ml-2'
                                title='Apri nello stesso Tab'
                                :style='{color: colors.bg.INFO}'
                                :icon="['fas', 'file-import']"
                                size='1x'
                                v-else
                                @click='linkInNewPage = false'
                            />
                        </template>
                        <template #body='slotProps'>
                            <template v-if='slotProps.data["has_quiz"]'>
                                <router-link
                                    v-for='quiz in slotProps.data["quiz"]'
                                    :key='quiz["id"]'
                                    :to='gotoId(quiz["id"])'
                                    :target='linkInNewPage ? "_blank" : "_self"'>
                                    <Button icon='pi pi-pencil'
                                            class='mr-2 mb-2'
                                            :class='{"p-button-success": !linkInNewPage && quiz["active"], "p-button-help": linkInNewPage && quiz["active"], "p-button-secondary": !quiz["active"] }'
                                            title='Modifica'></Button>
                                </router-link>
                            </template>
                            <template v-else>
                                <Button icon='pi pi-plus'
                                        class='mr-2 mb-2 p-button-info'
                                        :disabled='loading'
                                        @click='manuallyCreate(slotProps.data.id)'
                                        title='Crea Manualmente'></Button>
                                <ChatGPTButton
                                    :loading='chatGPTBooksGenerating[slotProps.data.id]'
                                    title='Crea con ChatGPT'
                                    label='-'
                                    :btnClass='"mr-2 mb-2"'
                                    @click='gptCreate(slotProps.data.id)' />
                            </template>

                        </template>
                    </Column>
                </DataTable>

            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Cont/Books');
const quizStore = createNamespacedHelpers('Cont/Quiz');

import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import ListHeader from '../../../components/4books/ListHeader';
import DDLanguages from '../../../mixins/dd_languages';
import ChatGPTButton from '../../../components/4books/ChatGPTButton.vue';

export default {
    components: {
        ListHeader, ChatGPTButton,
    },
    data() {
        return {
            filterOnQuiz: -1,
            isLoading: false,
            composed: [],
            selectedLanguage: 'it',
            chatGPTBooksGenerating: {}
        };
    },
    computed: {
        ...mapGetters(['rows', 'loading', 'quizLoading']),
        ...quizStore.mapGetters(['quizzes']),
        filtered() {
            if (this.filterOnQuiz === -1) {
                return this.composed;
            }
            if (this.filterOnQuiz === 2) {
                //with active quizzes
                return this.composed.filter(r => {
                    if (!r['has_quiz']) return false
                    if (!r['quiz']) return false
                    for (let i = 0; i < r['quiz'].length; i++){
                        if (r['quiz'][i]['active']) {
                            return true;
                        }
                    }
                    return false
                });
            }
            if (this.filterOnQuiz === 3) {
                //with all non-active quizzes
                return this.composed.filter(r => {
                    if (!r['has_quiz']) return false
                    if (!r['quiz']) return false
                    for (let i = 0; i < r['quiz'].length; i++){
                        if (r['quiz'][i]['active']) {
                            return false;
                        }
                    }
                    return true
                });
            }
            return this.composed.filter(r => {
                return r['has_quiz'] === this.filterOnQuiz;
            });
        },
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.refreshList();
    },
    methods: {
        ...mapActions(['fetchList', 'downloadSkillsAssociations', 'createQuiz']),
        ...quizStore.mapActions(['ListAll', 'createPlain']),
        ...quizStore.mapMutations(['addQuiz']),
        manuallyCreate(book_id) {
            this.createPlain({
                book_id: book_id,
                locale: this.selectedLanguage,
            }).then((res) => {
                this.addQuiz(res);
                this.navigateToId(res.id);
                //Goto Page via router
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        gptCreate(book_id) {
            this.chatGPTBooksGenerating['book_id'] = true;
            this.ShowWarning('Attenzione', 'La richiesta impiegherà diversi secondi a popolare domande e risposte quindi attendere prima di editare.');
            this.createQuiz({
                book_id: book_id,
                lang: this.selectedLanguage
            }).then((res) => {
                this.addQuiz(res['content']);
                //Goto Page via router
            }).catch(err => {
                this.ShowStandardError(err.response);
            }).finally(() => {
                this.chatGPTBooksGenerating['book_id'] = false;
            });
        },
        composeAllData(lastCall = false) {
            if (!this.rows || this.rows.length === 0) {
                if (lastCall) return;
                this.refreshList();
            }
            this.isLoading = true;
            this.ListAll(this.selectedLanguage)
                .then()
                .catch(err => {
                    this.ShowStandardError(err.response);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        joinData(q) {
            if (!this.rows || this.rows.length === 0) {
                return;
            }
            this.composed = [];
            this.rows.forEach(book => {
                book['has_quiz'] = (q && q[book['id']]) ? 1 : 0;
                book['quiz'] = null;
                if (book['has_quiz']) {
                    book['quiz'] = q[book['id']];
                }

                this.composed.push(book);
            });
        },
        refreshList() {
            this.composed = [];
            this.isLoading = true;
            this.fetchList().then(() => {}).catch(err => {
                this.ShowStandardError(err.response);
            }).finally(() => {
                this.isLoading = false;
            });
        },
        initFilters() {
            this.initFiltersByAllFields({});
            this.addFiltersByStartWith('slug');
        },
        clearFilter() {
            this.initFilters();
        },
    },
    watch: {
        selectedLanguage(n, o) {
            if (n === o) {
                return;
            }
            this.composeAllData(false);
        },
        rows(n) {
            if (!n || n.length === 0) {
                this.composed = [];
                return;
            }
            this.composeAllData(true);
        },
        quizzes(n) {
            this.joinData(n);
        },
    },
    mixins: [
        Notifications,
        Navigations,
        DDLanguages,
    ],
};
</script>
